import React, { Fragment, useState, useEffect } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import TitleH2Component from "../../components/TitleH2Component";
import InputComponent from "../../components/InputComponent";
import stylebtn from '../../styles/btn.module.scss';
import { Icon } from "@iconify/react/dist/iconify.js";
import ParrafoComponent from "../../components/ParrafoComponent";
import ModalGeneralComponent from "../../components/ModalGeneralComponent";
import { Editcajamenor, EliminarCajamenor, getCajamenor, Postcajamenor } from "../../api/Cajamenor";
import LoaderComponent from "../../components/LoaderComponent";
import PaginadorComponent from "../../components/PaginadorComponent";
import AlertComponent from "../../components/AlertComponent";
import useSumaPrecios from '../../hooks/useSumaPrecios';
import MoneyInputComponent from "../../components/MoneyInputComponent";
import TitleH4Component from "../../components/TitleH4Component";
const CajaMenorPage = () => {
    const [showEliminar, setShowEliminar] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [alert, setAlert] = useState(false);
    const [producto, setProduscto] = useState('')
    const [precio, setPrecio] = useState(0)
    const [currentItem, setCurrentItem] = useState(null);
    const [mensajealerta, setMensajealerta] = useState('')
    const [formData, setFormData] = useState({
        producto: '',
        precio: '',
    });

    const [formDataFecha, setFormDataFecha] = useState({
        fechaI: '',
        fechaFin: '',
    });
    const [nombreProducto, setNombreProducto] = useState('');


    // Metodos para el popup de eliminar
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = (item) => {
        setCurrentItem(item)
        setShowEliminar(true)
    };

    // Metodos para el popup de eliminar
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (item) => {
        setProduscto(item.producto);
        setPrecio(item.precio)
        setCurrentItem(item)
        setShowEdit(true)
    };


    const itemsPerPage = 7;
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const currentPageDatacm = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Manejar el cambio en el input
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const fetchData = async (filters = {}) => {
        setLoading(true);
        try {
            const response = await getCajamenor(filters);
            const formattedData = response.data.map(item => ({
                ...item,
                formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
                formattedPrice: Number(item.precio).toLocaleString('es-ES') // Formatea el precio con punto de miles
            }));

            const cmOrdenados = formattedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setData(cmOrdenados);
        } catch (error) {
            console.error('Error algo esta mal:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])



    const crearCajamenor = async () => {
        try {
            const cajamenor = await Postcajamenor(formData);

            if (!alert) {
                setMensaje(cajamenor.msg)
            }

            if (cajamenor.success) {
                fetchData();
                setFormData({
                    producto: '',
                    precio: ''
                })
                setMensajealerta(cajamenor.msg)
                setAlert(true);
            }
        } catch (error) {
            console.log(error);
        }


        setTimeout(() => {
            setAlert(false)
            setMensaje('')

        }, 5000)

    }

    const handlePEditChange = (e) => {
        setProduscto(e.target.value);
    }

    const handlePrecioEditChange = (e) => {
        setPrecio(e.target.value);
    }

    // Manejar el cambio en el input
    const handleChangeNombreProducto = (event) => {
        setNombreProducto(event.target.value);
    };


    // Manejar el cambio en el input
    const handleInputChangeFecha = (event) => {
        const { name, value } = event.target;
        setFormDataFecha({
            ...formDataFecha,
            [name]: value,
        });
    };

    // editar

    const editar = async () => {
        try {
            const edito = await Editcajamenor(currentItem.id, producto, precio);
            setMensaje(edito.msg)
            if (edito.success) {
                setAlert(true);
                setShowEdit(false);
                fetchData();
                setMensajealerta(edito.msg)
            }
        } catch (error) {
            console.log(error)
        }
        setTimeout(() => {
            setAlert(false)
            setMensaje('')

        }, 5000)
    }



    const eliminar = async () => {
        try {
            const res = await EliminarCajamenor(currentItem.id);

            if (res.success) {
                setShowEliminar(false);
                setAlert(true);
                fetchData();
                setMensajealerta(res.message)
            }
        } catch (error) {
            console.log(error);
        }

        setTimeout(() => {
            setAlert(false)
            setMensajealerta('')
        }, 5000)
    }


    // Usar el hook personalizado para obtener los totales
    const precios = useSumaPrecios(data);

    // Función para formatear los precios en punto mil
    const formatearPrecio = Number(precios.totalSemanaActual).toLocaleString('es-ES');


    // metodo para filtrar un producto de la caja menor 
    const handleFilterClick = (e) => {
        e.preventDefault();
        const filters = {
            searchQuery: nombreProducto,
            startDate: formDataFecha.fechaI,
            endDate: formDataFecha.fechaFin,
        };

        fetchData(filters);
    };

    return (
        <Fragment>
            <div className='d-flex justify-content-center'>
                <AlertComponent styleGroup="bg-soccess color-white" mensaje={mensajealerta} activaalert={alert} />
            </div>
            <HeaderComponent styleGroup="">
                <TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">CAJA MENOR</TitleH2Component>
            </HeaderComponent>

            <div className="conatiner-global pt-3 pb-5">
                <section className="mb-3">
                    <form action="" onSubmit={handleFilterClick}>
                        <div className="container">
                            <TitleH4Component styleGroup="box-text color-madera text-center fw700 roboto mb-1">Filtros por rango de fechas o nombre del producto</TitleH4Component>

                            <div className="row gy-4 d-flex justify-content-xl-end align-items-end">
                                {/**d-flex justify-content-end align-items-end*/}
                                <div className="col-12 col-md-6 col-xl-3">
                                    <InputComponent
                                        label="Fecha Inicio:"
                                        styleLabel="fw600 WorkSans"
                                        styleGroup="me-2"
                                        htmlFor="bloque"
                                        type="date"
                                        name="fechaI"
                                        value={formDataFecha.fechaI}
                                        onChange={handleInputChangeFecha}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <InputComponent
                                        label="Fecha Final:"
                                        styleLabel="fw600 WorkSans"
                                        styleGroup="me-2"
                                        htmlFor="bloque"
                                        type="date"
                                        name="fechaFin"
                                        value={formDataFecha.fechaFin}
                                        onChange={handleInputChangeFecha}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <div className="container_search">
                                        <div className="me-2">
                                            <Icon icon="gala:search" className="icon-search " />
                                        </div>

                                        <InputComponent
                                            styleGroup="w-100"
                                            styleinput="w-100"
                                            type="search"
                                            search
                                            name="numero"
                                            value={nombreProducto}
                                            onChange={handleChangeNombreProducto}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <button
                                        type="submit"
                                        className={`${stylebtn.btn} ${stylebtn.primary} w-100`}
                                    >
                                        Buacar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                {loading ? (
                    <LoaderComponent styleGroup="mt-5" />
                ) :
                    <Fragment>
                        <div className="container-fluid ">
                            <form action="" className="mt-5">
                            <TitleH4Component styleGroup="box-text color-madera text-center fw700 roboto mb-1">Registar caja menor</TitleH4Component>

                                {!alert && <ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>}

                                <div className="row d-flex align-items-center ">
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <InputComponent
                                            label="Producto"
                                            placeholder="Ingerse producto"
                                            styleLabel="fw600 WorkSans"
                                            styleGroup=" mb-2"
                                            htmlFor="bloque"
                                            type="text"
                                            value={formData.producto}
                                            name="producto"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <MoneyInputComponent
                                            label="Precio"
                                            placeholder="Ingerse precio"
                                            styleLabel="fw600 WorkSans"
                                            styleGroup=" mb-2"
                                            //styleGroup={`${stylegeneral.col}`}
                                            value={formData.precio}
                                            name="precio"
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <button type="button" className={`${stylebtn.btn} ${stylebtn.primary} mt-4`} onClick={crearCajamenor}>Registrar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive mt-4">
                            <ParrafoComponent styleGroup=" color-madera fw700 WorkSans ">Total de gasto de la semana : ${formatearPrecio}</ParrafoComponent>
                            <table className="table table-bordered tableGeneral">
                                <thead>
                                    <tr >
                                        <th scope="col " className="color-madera">Producto</th>
                                        <th scope="col" className="color-madera">Fecha de gastos</th>
                                        <th scope="col" className="color-madera">Precio</th>
                                        <th scope="col" colspan="2" className="color-madera text-center">Acciones</th>

                                    </tr>
                                </thead>
                                {data.length !== 0 ? <tbody>
                                    {currentPageDatacm.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <th className="text-center">
                                                    {item.producto}
                                                </th>
                                                <td className="text-center">{item.formattedDate}</td>
                                                <td className="text-center">${item.formattedPrice}</td>
                                                <td>
                                                    <button className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`} onClick={() => handleShowEdit(item)}> <Icon icon="raphael:edit" /></button>
                                                </td>
                                                <td>
                                                    <button className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`} onClick={() => handleShowEliminar(item)} > <Icon icon="majesticons:delete-bin" /></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr >
                                            <td colSpan={5} className="text-center">SIN DATOS</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                            {pageCount > 1 && (
                                <PaginadorComponent
                                    setCurrentPage={setCurrentPage}
                                    pages={pageCount}
                                    styleGroup="d-flex justify-content-center pt-4"
                                />
                            )}
                        </div>
                    </Fragment>}
            </div>

            {/** modal eliminar un producto del carrito **/}
            <section >
                <ModalGeneralComponent styleGroup="d-flex align-items-center modal-general " styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'} show={showEliminar} handleClose={handleCloseEliminar} >
                    <form action="" >
                        <TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar el producto con nombre ${currentItem?.producto} con precio ${currentItem?.formattedPrice}?`}</TitleH2Component>

                        <div className="d-flex justify-content-end">
                            <button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>Cancelar</button>
                            <button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={eliminar}>SI</button>
                        </div>
                    </form>
                </ModalGeneralComponent>
            </section>

            {/** modal editar informacion de caja menor**/}
            <section >
                <ModalGeneralComponent ModalHeader titulo="Editar la Informacion" styleGroup={`d-flex align-items-center modal-general `} styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'} show={showEdit} handleClose={handleCloseEdit} >
                    <form action="" >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <InputComponent
                                        label="Producto"
                                        placeholder="Ingerse producto"
                                        styleLabel="fw600 WorkSans"
                                        styleGroup=" mb-2"
                                        htmlFor="bloque"
                                        type="text"
                                        value={producto}
                                        name="producto"
                                        onChange={handlePEditChange}

                                    />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <MoneyInputComponent
                                        label="Precio"
                                        placeholder="Ingerse precio"
                                        styleLabel="fw600 WorkSans"
                                        styleGroup=" mb-2"
                                        htmlFor="bloque"
                                        type="text"
                                        value={precio}
                                        name="precio"
                                        onChange={handlePrecioEditChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="button" onClick={handleCloseEdit} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>Cancelar</button>
                            <button type="button" className={` btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={editar}>Continuar</button>
                        </div>
                    </form>
                </ModalGeneralComponent>

            </section>
        </Fragment>
    )
}

export default CajaMenorPage;