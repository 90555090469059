import React, { Fragment, useEffect, useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import TitleH2Component from "../../components/TitleH2Component";
import { EliminarPersona, getClientes } from "../../api/Clientes";
import PaginadorComponent from "../../components/PaginadorComponent";
import { Icon } from "@iconify/react/dist/iconify.js";
import LoaderComponent from "../../components/LoaderComponent";
import stylebtn from '../../styles/btn.module.scss';
import { Link } from "react-router-dom";
import ModalGeneralComponent from "../../components/ModalGeneralComponent";
import AlertComponent from "../../components/AlertComponent";
import InputComponent from "../../components/InputComponent";
import ParrafoComponent from "../../components/ParrafoComponent";
import { EditarPersona } from "../../api/Empleado";

const ClientesPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [showEliminar, setShowEliminar] = useState(false);
    const [currentCliente, setCurrentCliente] = useState([]);
    const [alert, setAlert] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [formDataPersona, setFormDataPersona] = useState({
        cedula: '',
        nombre: '',
        apellido: '',
        direccion: '',
        celular: '',
    });

    const itemsPerPage = 7;
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const currentPageDataclientes = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    //metodos para el popup de eliminar un cliente 
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = async (cliente) => {
        setShowEliminar(true);
        setCurrentCliente(cliente);
    }

    const handleClose = () => {
        setShowEdit(false);
    };

    //metodo para mostrar el popup de editar
    const handleShowEditar = (item) => {
        setShowEdit(true);
        setCurrentCliente(item);
        setFormDataPersona({
            cedula: item?.Persona?.cedula,
            nombre: item?.Persona?.nombre,
            apellido: item?.Persona?.apellido,
            direccion: item?.Persona?.direccion,
            celular: item?.Persona?.celular,
        });
    };

    // Manejar el cambio en el input
    const handleInputChangePersona = (event) => {
        const { name, value } = event.target;
        setFormDataPersona({
            ...formDataPersona,
            [name]: value,
        });
    };

    const mostrarClientes = async (filters = {}) => {
        setLoading(true);
        try {
            const res = await getClientes(filters);
            setData(res?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        mostrarClientes();
    }, [])


    const eliminarCliente = async () => {
        try {
            const res = await EliminarPersona(currentCliente?.Persona?.idP);
            if (res.success) {
                handleCloseEliminar();
                mostrarClientes();
                setAlert(true);
                setMensaje(res.message);
            }
        } catch (error) {
            console.log(error);
        }

        setTimeout(() => {
            setMensaje('')
            setAlert(false)
        }, [5000]);
    }

    const editarcliente = async (e) => {
        e.preventDefault();
        try {
            const resP = await EditarPersona(currentCliente?.Persona?.idP, formDataPersona);
            if (resP.success) {
                setAlert(true);
                setMensaje(resP.msg);
                mostrarClientes();
                handleClose();
            }

        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            setAlert(false);
            setMensaje('');
        }, 3000);
    }


    const buscarcliente = (e) => {
        e.preventDefault();
        const filters = {
            searchQuery: searchQuery,
        };

        mostrarClientes(filters);
    }

    return (
        <Fragment>

            <div className='d-flex justify-content-center'>
                <AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
            </div>
            <HeaderComponent>
                <TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">CLIENTES</TitleH2Component>
            </HeaderComponent>
            {loading ? (
                <LoaderComponent styleGroup="mt-5" />
            ) : (
                <div className="conatiner-global pt-3 mb-5">

                    <section>
                        <div className="container">
                            <form action="" onSubmit={buscarcliente}>
                                <div className="row gy-4 d-flex justify-content-end align-items-end">
                                    <div className="col-12 col-md-6 col-xl-3">
                                        <InputComponent
                                            placeholder="Ingresar documento o nombre"
                                            label="Buscar cliente"
                                            styleLabel="fw600 WorkSans"
                                            htmlFor="bloque"
                                            type="search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-2">
                                        <button type="submit" className={`${stylebtn.btn} ${stylebtn.primary} `}>
                                            Buscar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>

                    <section>
                        <div className="table-responsive mt-4">
                            <table className="table table-bordered tableGeneral">
                                <thead>
                                    <tr>
                                        <th scope="col " className="color-madera">
                                            #
                                        </th>
                                        <th scope="col " className="color-madera">
                                            CC
                                        </th>
                                        <th scope="col" className="color-madera">
                                            Nombres
                                        </th>
                                        <th scope="col" className="color-madera">
                                            Apellidos
                                        </th>
                                        <th scope="col" className="color-madera">
                                            Celular
                                        </th>
                                        <th scope="col" className="color-madera">
                                            Direccion
                                        </th>

                                    </tr>
                                </thead>
                                {currentPageDataclientes && currentPageDataclientes?.length !== 0 ? (
                                    <tbody>
                                        {currentPageDataclientes?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>{index + 1}</th>
                                                    <td>{item?.Persona?.cedula}</td>
                                                    <td>{item?.Persona?.nombre}</td>
                                                    <td>{item?.Persona?.apellido}</td>
                                                    <td>{item?.Persona?.celular}</td>
                                                    <td>{item?.Persona?.direccion}</td>

                                                    <td>
                                                        <button
                                                            onClick={() => handleShowEditar(item)}
                                                            className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
                                                        >
                                                            {' '}
                                                            <Icon icon="raphael:edit" />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                handleShowEliminar(item);
                                                            }}
                                                            className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
                                                        >
                                                            {' '}
                                                            <Icon icon="majesticons:delete-bin" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            {' '}
                                            <td colSpan={12} className="text-center">
                                                SIN CLIENTES
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>

                    </section>

                    {pageCount > 1 && (
                        <PaginadorComponent
                            setCurrentPage={setCurrentPage}
                            pages={pageCount}
                            styleGroup="d-flex justify-content-center pt-4"
                        />
                    )}

                    <div className='d-flex justify-content-end'>
                        <Link
                            className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-dark`}
                            to={'/configuracion'}
                        >
                            {' '}
                            <Icon icon="icon-park-solid:back" />
                        </Link>
                    </div>
                </div>
            )}


            {/** modal eliminar un cliente**/}
            <section>
                <ModalGeneralComponent
                    styleGroup="d-flex align-items-center modal-general "
                    styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
                    show={showEliminar}
                    handleClose={handleCloseEliminar}
                >
                    <form action="">
                        <TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar a ${currentCliente?.Persona?.nombre} ${currentCliente?.Persona?.apellido} ?`}</TitleH2Component>

                        <div className="d-flex justify-content-end">
                            <button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
                                Cancelar
                            </button>
                            <button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={eliminarCliente}>
                                Si
                            </button>
                        </div>
                    </form>
                </ModalGeneralComponent>
            </section>

            {/** modal editar Cliente**/}
            <section>
                <ModalGeneralComponent
                    ModalHeader
                    titulo={`${'EDITAR REGISTRAR CLIENTE'} `}
                    styleGroup="d-flex align-items-center modal-general "
                    styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
                    show={showEdit}
                    handleClose={handleClose}
                    handleShow={handleShowEditar}
                >
                    <div className="container">
                        <form action="" onSubmit={editarcliente}>
                            {!alert && (
                                <ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
                            )}

                            <div className="row g-4">
                                <div className="col-12 col-sm-6">
                                    <input
                                        type="text"
                                        htmlFor="cudula"
                                        className="form-control fw600 WorkSans mb-4"
                                        placeholder="Ingrese la cc"
                                        name="cedula"
                                        value={formDataPersona.cedula}
                                        onChange={handleInputChangePersona}
                                    />
                                </div>
                                <div className="col-12">
                                    <InputComponent
                                        placeholder="Nombres"
                                        styleLabel="fw600 WorkSans"
                                        htmlFor="bloque"
                                        type="text"
                                        value={formDataPersona.nombre}
                                        name="nombre"
                                        onChange={handleInputChangePersona}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <InputComponent
                                        placeholder="Apellidos"
                                        styleLabel="fw600 WorkSans"
                                        htmlFor="bloque"
                                        type="text"
                                        value={formDataPersona.apellido}
                                        name="apellido"
                                        onChange={handleInputChangePersona}
                                    />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <InputComponent
                                        placeholder="Celular"
                                        styleLabel="fw600 WorkSans"
                                        htmlFor="bloque"
                                        type="number"
                                        value={formDataPersona.celular}
                                        name="celular"
                                        onChange={handleInputChangePersona}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <InputComponent
                                        placeholder="Direccion"
                                        styleLabel="fw600 WorkSans"
                                        htmlFor="bloque"
                                        type="text"
                                        value={formDataPersona.direccion}
                                        name="direccion"
                                        onChange={handleInputChangePersona}
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" onClick={handleClose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
                                    Cancelar
                                </button>
                                {
                                    <button type="submit" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
                                        {'Editar'}
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </ModalGeneralComponent>
            </section>
        </Fragment>
    )
}
export default ClientesPage;