import instance from "../config";

// metodo para trae todos los clientes 

const getClientes = async (params) => {

    try {
        const response = await instance.get('/api/clientes',{params});
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// metodo para eliminar un clente
const EliminarPersona = async (idP) => {
    try {
        const response = await instance.delete(`/api/persona/${idP}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

export {
    getClientes,
    EliminarPersona,
}